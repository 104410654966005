.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
}

.upload-btn {
  border: 1px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 1em;
  font-weight: 500;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.react-confirm-alert-overlay {
  z-index: 999 !important;
}

.react-fancybox .thumbnail img {
  max-width: 200px !important;
}

@media (max-width: 959.95px) {
  .MuiTable-root > .tableButtonStyle {
    font-size: 1rem !important;
  }

  .MuiTableCell-body {
    font-size: 0.8rem !important;
    width: 35% !important;
    height: 60px !important;
  }
}

.MuiTableCell-root > .tableButtonStyle {
  font-size: 0.7rem !important;
}

.fa-check-circle {
  color: green;
}

.create-bill-indicators {
  padding: 10px;
  border: 1px solid #1475ef;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  margin-right: 3rem !important;
}

.create-bill-header {
  color: #272343;
  font-size: 1.188rem !important;
  font-weight: 500 !important;
  margin-top: 34px;
}
.create-bill-subheader {
  color: #9898b1;
  font-weight: 500 !important;
  font-size: 1rem !important;
}
.create-bill-form-headers {
  color: #272343;
  font-weight: 500 !important;
  font-size: 1rem !important;
}
.mr-top-8 {
  margin-top: 8px;
}

.create-bill-form-labels {
  font-weight: 600;
  font-size: 0.875rem !important;
  color: #8f95b2;
}
