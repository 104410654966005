.MuiPaper-root {
  width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.label-info {
  font-size: 11px;
}

/*

.MUIDataTableToolbar-actions-317:first-child {
background-color: blue !important;
}



.MUIDataTableToolbar-actions-617:first-of-type {
  color: brown;
}
*/
